import loadable from '@loadable/component';
import { getImage, ImageQuery } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import { Layout } from '../components/layout';
import { NumberedSection } from '../components/numbered-section';
import { BannerImage, Heroic } from '../components/reason/heroic';
import { reasonsData, whyOwnUpData } from '../data/content/3-reasons';
import { metadata } from '../data/metadata/images';
import { colors } from '../modules/colors';

const Divider = loadable(() => import('../components/divider'), {
  resolveComponent: (components) => components.Divider
});

const FullWidthField = loadable(() => import('../components/full-width-field'), {
  resolveComponent: (components) => components.FullWidthField
});

const FullWidthFieldHeader = loadable(() => import('../components/full-width-field'), {
  resolveComponent: (components) => components.FullWidthFieldHeader
});

const PressMentions = loadable(() => import('../components/press'), {
  resolveComponent: (components) => components.PressMentions
});

const QueriedPress = loadable(() => import('../components/press'), {
  resolveComponent: (components) => components.QueriedPress
});

const Spacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.Spacer
});

export const query = graphql`
  {
    images: allFile(filter: { relativePath: { regex: "/3-reasons//" }, extension: { ne: "svg" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

const imageStyle = {
  maxWidth: 390,
  margin: 'auto',
  position: 'relative' as const,
  borderRadius: '50%'
};

type ReasonsData = typeof reasonsData;

const Reasons = ({
  imageData,
  reasonsData
}: {
  imageData: ImageQuery;
  reasonsData: ReasonsData;
}) => {
  return reasonsData.map((data, index) => {
    const Image = getImage(imageData, data.imageName, metadata, imageStyle);

    return (
      <Fragment key={`reason-${index + 1}`}>
        <NumberedSection {...data} Image={Image} label="Reasons you're overpaying" />
        {index < reasonsData.length - 1 ? <Divider /> : null}
      </Fragment>
    );
  });
};

type WhyOwnUpData = typeof whyOwnUpData;

const WhyOwnUp = ({
  imageData,
  whyOwnUpData
}: {
  imageData: ImageQuery;
  whyOwnUpData: WhyOwnUpData;
}) => {
  return whyOwnUpData.map((data, index) => {
    const Image = getImage(imageData, data.imageName, metadata, imageStyle);

    return (
      <Fragment key={`why-own-up-${index + 1}`}>
        <NumberedSection {...data} Image={Image} label="Why use Own Up" />
        {index < whyOwnUpData.length - 1 ? <Divider /> : null}
      </Fragment>
    );
  });
};

export const ThreeReasons = ({ data }: { data: ImageQuery }) => (
  <Layout>
    <Heroic BannerImage={BannerImage} />
    {Reasons({ imageData: data, reasonsData })}
    <Spacer />
    <FullWidthField backgroundColor={colors.LIGHT_GREY}>
      <FullWidthFieldHeader>
        We created Own Up to get you the best deal on your mortgage and to protect you from these 3
        expensive pitfalls:
      </FullWidthFieldHeader>
    </FullWidthField>
    <Spacer />
    {WhyOwnUp({ imageData: data, whyOwnUpData })}
    <PressMentions>
      <QueriedPress />
    </PressMentions>
  </Layout>
);

export default ThreeReasons;
