import { GetStartedLink } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpHeader
} from '@rategravity/own-up-component-library';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { metadata } from '../../data/metadata/images';
import { useMixpanel } from '../../hooks/use-mixpanel';
import { HeroicButton } from '../button';
import { Highlight } from '../typography';

export const BannerImage = () => {
  const {
    file: {
      childImageSharp: { gatsbyImageData }
    }
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "3-reasons/3-reasons-banner.jpeg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <GatsbyImage
      image={gatsbyImageData}
      alt={metadata['3-reasons-banner.jpeg'].alt}
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%'
      }}
      loading="eager"
    />
  );
};

const BannerWrapperStyle = createOwnUpStyle({
  maxWidth: '96%',
  marginBottom: 50,
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  variants: {
    mediumAndDown: {
      marginBottom: 0
    }
  }
});

const BannerWrapper = createOwnUpComponent(OwnUpBox, BannerWrapperStyle);

const BannerContentStyle = createOwnUpStyle({
  maxWidth: 400,
  padding: '140px 64px 20px',
  position: 'relative',
  variants: {
    mediumAndDown: {
      paddingTop: 55
    },
    smallAndDown: {
      maxWidth: 250,
      paddingLeft: 32,
      paddingRight: 32
    }
  }
});

const BannerContent = createOwnUpComponent(OwnUpBox, BannerContentStyle);

const BannerTextStyle = createOwnUpStyle({
  ...fonts.TIEMPOS_HEADLINE_MEDIUM,
  fontFamily: "'TiemposHeadline-Medium', 'Palatino', 'Times New Roman', 'serif'",
  lineHeight: 1.5,
  variants: {
    smallAndDown: {
      fontSize: 30
    },
    medium: {
      fontSize: 35
    },
    large: {
      fontSize: 50
    }
  }
});

const BannerText = createOwnUpComponent(OwnUpHeader, BannerTextStyle);

const AnchoredButton = () => {
  const mixpanel = useMixpanel();

  return (
    <GetStartedLink
      discover={`${process.env.GATSBY_DISCOVER}/get-started`}
      mixpanelEvent={{
        hook: mixpanel,
        name: 'Click discover CTA',
        properties: { 'page-location': '3R-hero' }
      }}
    >
      <OwnUpBox style={{ marginTop: 30, marginBottom: 30, display: 'inline-block' }}>
        <HeroicButton variant="banner">GET STARTED</HeroicButton>
      </OwnUpBox>
    </GetStartedLink>
  );
};

interface HeroicProps {
  BannerImage: () => JSX.Element;
}

export const Heroic = ({ BannerImage }: HeroicProps) => (
  <BannerWrapper>
    <BannerImage />
    <BannerContent>
      <BannerText variant="title">
        <Highlight>3 reasons you&apos;re overpaying for your mortgage</Highlight>
      </BannerText>
      <AnchoredButton />
    </BannerContent>
  </BannerWrapper>
);
